import _ from 'lodash';

import img_getmoney from './logo/getmoney.png'
import img_beriberu from './logo/logo_beriberu.png'
import img_telezaim from './logo/logo_telezaim.png'
import img_moizaem from './logo/moizaem.png'
import img_prostozaim from './logo/prostozaim.png'
import img_zaemberi from './logo/zaemberi.png'
import {Card} from './Card';

import cls from './Cards.module.scss'

export interface IOffer {
    name: string
    logo: string
    percent: string
    date: string
    value: string
    age: string
    URL: string
}

const items:IOffer[] = [
    {
        name: 'GetMoney',
        logo: img_getmoney,
        percent: '0-0,8% в день',
        date: 'до 30 дней',
        value: 'до 30 000 ₽',
        age: 'от 18 лет',
        URL: 'https://togetmoney.ru',
    },
    {
        name: 'Telezaim',
        logo: img_telezaim,
        percent: '0-0,8% в день',
        date: 'от 1 дня до 12 месяцев',
        value: 'от 1 000 до 80 000 ₽',
        age: 'от 18 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=113938&offer_id=4760&p=7445&erid=LjN8KF7rt',
    },
    {
        name: 'BeriBeru',
        logo: img_beriberu,
        percent: '0-0,8% в день',
        date: 'от 5 до 30 дней',
        value: 'от 5 000 до 30 000 ₽',
        age: 'от 18 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=113938&offer_id=6082&p=7445&erid=LjN8KJZFq',
    },
    {
        name: 'ZaemBeri',
        logo: img_zaemberi,
        percent: '0-0,8% в день',
        date: 'от 5 дней до 12 месяцев',
        value: 'от 5 000 до 100 000 ₽',
        age: 'от 18 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=113938&offer_id=5342&p=7445&erid=LjN8KPwQU',
    },
    {
        name: 'MoiZaem',
        logo: img_moizaem,
        percent: '0-0,8% в день',
        date: 'от 5 до 30 дней',
        value: '1 000 до 100 000 ₽',
        age: 'от 18 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=113938&offer_id=6173&p=7445&erid=LjN8K6hm3',
    },
    {
        name: 'ProstoZaim',
        logo: img_prostozaim,
        percent: '0-0,8% в день',
        date: 'от 5 до 30 дней',
        value: '1 000 до 100 000 ₽',
        age: 'от 18 до 90 лет',
        URL: 'https://go.leadgid.ru/aff_c?aff_id=113938&offer_id=6358&p=7445&erid=LjN8Jzf1o',
    },

]

export const Cards = () => (
    <div className={cls.cards}>
        {_.map(items, (item) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Card key={item.name} {...item}/>
        ))}
    </div>
)