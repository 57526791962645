import React from 'react';
import cn from 'classnames'
import {observer} from 'mobx-react-lite';

import {Disclamer} from './Disclamer/Disclamer';
import {Instruction} from './Instruction/Instruction';
import {Cards} from './Offers/Cards';

export const Offers = observer(() => (
        <div className={cn(['content'])}>
            <Cards/>
            <Disclamer/>
            <Instruction/>
        </div>
    ))


