import React from 'react';
import {Link} from 'react-router-dom';

import { INDEX} from '../../config/routes';
import logo from '../../images/OnlyCash-Logo-White.svg'

import cls from './Footer.module.scss'

const Footer = () => (
        <div className={cls.footer}>
            <div className={cls.content}>

                <div className={cls.logo}>
                    <Link to={INDEX}>
                        <img src={logo} alt={'logo'}/>
                    </Link>
                    <div className={cls.caption}>
                        <p>© Only Cash, 2024</p>
                        <p>Все права защищены</p>
                    </div>
                </div>


                <div className={cls.policy}>
                    <div>
                        Сайт OnlyCash не является финансовым учреждением и не занимается выдачей различных кредитных продуктов пользователям сайта (кредитов и займов). Проект выступает в качестве агрегатора финансовых продуктов, представленных различными кредитными организациями, которые имеют все обязательные лицензии и сертификаты. Сайт не несет ответственность за условия, представленные кредиторами, а также за любые заключенные договоры кредитования. Расчеты на сайте носят приблизительный характер, окончательные условия по услугам микрофинансирования уточняйте на сайте кредитной организации.
                    </div>
                    <div className={cls.legal}>
                        <span>ООО «Гет Мани»</span>
                        <span>ИНН: 9724148897</span>
                        <span><a href={'mailto:info@togetmoney.ru'} target={'_blank'} rel={'noreferrer'}>info@togetmoney.ru</a></span>
                    </div>
                    <div>Москва, вн. Тер. Г. Муниципальный округ Нагатино-Садовники, проезд Хлебозаводский, д. 7, стр. 9, помещение 24/Н</div>

                </div>



            </div>
        </div>
    )

export default Footer