export const passportRegex = /^\d{4} \d{6}$/
export const accountRegex = /^\d{20}$/
export const bankRegex = /^\d{9}$/
export const INNRegex = /^\d{10}$/
export const codeRegex = /^\d{4}$/
// export const phoneRegex = /^\+7\s\([67]\d{2}\)\s\d{3}-\d{2}-\d{2}$/
export const phoneRegex = /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/
// export const certRegex = /^(\d{3}(\d|[A-Z]){2}[A-Z]{3}\d{10})|(\d{2}KZK\d{10})|(\d{2}[A-Z]{4}\d{9})|(\d{2}[A-Z]{4}\d{10})$/
export const certRegex = /^(\d|[A-Z]){8}\d{4,10}$/
export const activateRegex = /^(\d){2}WTM\d{10}$/

