import {Block} from './Block';

import cls from './Instruction.module.scss'

export const Instruction = () => (
    <div className={cls.instruction}>
        <h2>Как это работает?</h2>
        <div className={cls.blocks}>
            <Block number={'1'} title={'Зарегистрируйтесь на нашем сайте'} text={'Это займет не больше 2х минут, указывайте только достоверные данные о себе.'}/>
            <Block number={'2'} title={'Выберите предложение'} text={'Мы предоставим выбор из нескольких кредитных предложений и компаний.'}/>
            <Block number={'3'} title={'Оформите займ онлайн'} text={'Понадобится паспорт и телефон. Подтверждение мгновенно.'}/>
            <Block number={'4'} title={'Получите деньги'} text={'Получите деньги на карту онлайн не выходя из дома без проверок.'}/>
        </div>
    </div>
)