import React, {FC} from 'react';
import {provider, useInstance} from 'react-ioc';
import {BrowserRouter as Router, Navigate, Route, Routes,} from 'react-router-dom';
import {YMInitializer} from 'react-yandex-metrika';
import {ApolloClient, ApolloProvider} from '@apollo/client';
import {ConfigProvider} from 'antd';
import ru_RU from 'antd/locale/ru_RU';
import {observer} from 'mobx-react-lite';

import 'dayjs/locale/ru';

import ScrollToTop from '../components/common/ScrollToTop';
import Layout from '../components/layout/Layout';
import {
    INDEX,
    NOT_FOUND, OFFERS, REGISTRATION,
    ROOT,
} from '../config/routes';
import ApolloClientDI from '../model/graphql/ApolloClientDI';
import StoreDI from '../model/store/StoreDI';
import Index from '../pages/index/Index';
import {Offers} from '../pages/index/Offers';
import NotFound from '../pages/NotFound';
import Registration from '../pages/registration/Registration';
import { Metrika } from '../shared/helpers/Metrika';

import './App.scss';
const App: FC = () => {

    const apolloClient = useInstance(ApolloClient);

    return (
        <ConfigProvider locale={ru_RU} theme={{
            token: {
                colorPrimary: '#B3598F',
                borderRadius: 8,
                fontFamily: '\'Open Sans\', sans-serif',
                fontSize: 16,
            },
        }}>
            <ApolloProvider client={apolloClient}>
                <Router>
                    <ScrollToTop/>
                    <YMInitializer accounts={[97779979]}/>
                    <Metrika/>

                    <Routes>

                        <Route path={ROOT} element={<Layout/>}>
                            <Route path={INDEX} element={<Index/>}/>
                            <Route path={REGISTRATION} element={<Registration/>}/>
                            <Route path={OFFERS} element={<Offers/>}/>
                            <Route path="*" element={<Navigate to={INDEX}/>}/>
                            <Route path={NOT_FOUND} element={<NotFound/>}/>
                        </Route>
                    </Routes>
                </Router>
            </ApolloProvider>
        </ConfigProvider>
);
};

export default provider(
StoreDI(),
ApolloClientDI(),
)(observer(App));
