// INDEX
export const ROOT = '';
export const INDEX = '/';
export const REGISTRATION = '/registration';
export const OFFERS = '/offers/SDR8KzArwCTW9Vy6Q7excL';
export const CODE = '/code';
export const DONE = '/done';
export const POLICY = '/docs/Политика_обработки_персональных_данных_OnlyCash.pdf';
export const NOT_FOUND = '/404';

