import React, {FC} from 'react';
import {useInstance} from 'react-ioc';
import cn from 'classnames'
import {observer} from 'mobx-react-lite';

import {Store} from '../../model/store/Store';

import {Banner} from './Banner/Banner';
import {Disclamer} from './Disclamer/Disclamer';
import {Instruction} from './Instruction/Instruction';
import {Cards} from './Offers/Cards';

const Index: FC = observer(() => {

    const {auth} = useInstance(Store)

    return (
            <div className={cn(['content'])}>
                {auth.auth
                    ?
                        <Cards/>
                    :
                        <Banner/>
                }
                <Disclamer/>
                <Instruction/>
            </div>
    )
})

export default Index;



