import React, {FC, useState} from 'react';
import { Steps} from 'antd';
import cn from 'classnames'

import {Code} from './Code';
import {Done} from './Done';
import {RegForm} from './RegForm';

import cls from './Registration.module.scss'

const Registration: FC = () => {

    const [step, setStep] = useState<number>(0)

    return (
        <>
            <div className={cn([cls.registration, 'content'])}>

                <h2>Регистрация</h2>

                <Steps
                    current={step}
                    items={[
                        {
                            title: 'Заполните форму',
                        },
                        {
                            title: 'Введите код',
                        },
                        {
                            title: 'Готово',
                        },
                    ]}
                />

                {step === 0 && <RegForm onComplete={() => setStep(1)}/>}
                {step === 1 && <Code onComplete={() => setStep(2)} onBack={() => setStep(0)}/>}
                {step === 2 && <Done/>}


            </div>
        </>
    );
}

export default Registration;



