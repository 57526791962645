import {useInstance} from 'react-ioc';
import {Link, useNavigate} from 'react-router-dom';
import {LoginOutlined, LogoutOutlined} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import {observer} from 'mobx-react-lite';

import {INDEX, REGISTRATION} from '../../config/routes';
import logo from '../../images/OnlyCash-Logo.svg';
import {Store} from '../../model/store/Store';

import cls from './Header.module.scss';


const Header = observer(() => {

    const navigate = useNavigate()

    const {auth} = useInstance(Store)

    return (
        <div className={cls.header}>
            <div className={cls.top}>
                <div>
                    <Link to={INDEX}>
                        <img src={logo} alt={'Only Cash Logo'} className={cls.logo}/>
                    </Link>
                </div>
                <div>
                    {auth.auth
                        ?
                        <Tooltip title={'Выйти'}><Button type={'text'} icon={<LogoutOutlined/>}
                                onClick={() => auth.clearState()}>{auth.FIO}</Button></Tooltip>
                        :
                        <Button type={'text'} icon={<LoginOutlined/>}
                                         onClick={() => navigate(REGISTRATION)}>Регистрация</Button>
                    }

                </div>
            </div>
        </div>
    )
})

export default Header;