import {Button} from 'antd';

import {IOffer} from './Cards';

import cls from './Cards.module.scss'


export const Card = ({name, logo, value, percent, date, age, URL}: IOffer) => (
    <div className={cls.card}>
        <div className={cls.wrapper}>

            <img className={cls.logo} src={logo} alt={name}/>

            <div className={cls.title}>{value}</div>

            <div className={cls.line}><span>Ставка</span><span className={'bold'}>{percent}</span></div>
            <div className={cls.line}><span>Срок</span><span className={'bold'}>{date}</span></div>
            <div className={cls.line}><span>Возраст</span><span className={'bold'}>{age}</span></div>

            <a href={URL} target={'_blank'} rel={'noreferrer'}><Button type={'primary'} size={'large'} block>Получить деньги</Button></a>

        </div>
    </div>
)