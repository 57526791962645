import cn from 'classnames';

import cls from './Instruction.module.scss'

interface IBlock {

    number: string,
    title: string,
    text: string,
}

export const Block = ({number, title, text}: IBlock) => (
    <div className={cn([cls.block])}>
        <div className={cn(['highlight', cls.number])}>{number}</div>
        <div className={'bold'}>{title}</div>
        <div className={cls.text}>{text}</div>
    </div>
)