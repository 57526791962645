import {useNavigate} from 'react-router-dom';
import {CheckOutlined, RightOutlined} from '@ant-design/icons';
import { Button} from 'antd'
import cn from 'classnames'

import {INDEX} from '../../config/routes';

import cls from './Registration.module.scss'

export const Done = () => {

    const navigate = useNavigate()

    return (
       <div className={cls.done}>
           <div className={cn(['highlight', 'green'])}><CheckOutlined/> Успешно</div>
           <div>
               <p>Вы успешно зарегистрированы.</p>
               <p>Теперь вам доступен полный функционал платформы.</p>
           </div>
           <Button type={'primary'} size={'large'} onClick={() => navigate(INDEX)} icon={<RightOutlined/>}>На главную</Button>
       </div>
    )
}