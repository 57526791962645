import {applySnapshot, types as t,} from 'mobx-state-tree';

export const initialAuthStore = {
    token: null,
    auth: null,
    FIO: null,
    Phone: null,
    SendingDate: null,
};

export const AuthStore = t
    .model({
        token: t.maybeNull(t.string),
        auth: t.maybeNull(t.boolean),
        FIO: t.maybeNull(t.string),
        Phone: t.maybeNull(t.string),
        SendingDate: t.maybeNull(t.string),
    })
    .actions((self) => ({
        clearState: () => {
            applySnapshot(self, initialAuthStore);
        },
        setAuth: (auth: boolean | null) => {
            self.auth = auth;
        },
        setData: (FIO: string | null, Phone: string | null, SendingDate: string | null) => {
            self.FIO = FIO;
            self.Phone = Phone;
            self.SendingDate = SendingDate
        },
    }));
